p, span, li, label, h1, h2, h3, h4, tr {
  color: #69645A;
  /* color: red; */
}

.text-grey {
  color: #69645A;
  /* color: red; */
}

.login-wrapper {
  width: 30rem;
  max-width: 30rem;
  padding: 3rem;

  border-radius: 5px;

  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-logo-wrapper {
  margin-bottom: 20px;
}

.login-logo {
  display: block;
  margin: auto;
}

.form-error {
  display: block;
}

.navbar-color {
  background-color: #00005d;
  height: 5rem;
}

.pools {
  height: calc(100vh - 10rem);
  max-height: calc(100vh - 10rem);
}

.container-fluid {
  padding: 0!important;
}

.row {
  margin: 0!important;
}

.select-block {
  display: block;
  width: 100%;
}

.releve-element-right {
  position: fixed;
  right: 20px;
}

.danger {
  color: #ff5e5e;
}

.warning {
  color: #ffc636;
}

.login-page {
  width: 100vw;
  height: 100vh;
  background-image: url('/banner.jpg');
  background-size: cover;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline::before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li::before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid blue;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

li.list-alert,li.list-warning,li.list-success {
  border: solid 1px;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
}

li.list-alert::before {
  border-color: #ff5e5e!important;
}

li.list-warning::before {
  border-color: #ffc636!important;
}

li.list-success::before {
  border-color: #1ABC9C!important;
}

.indicator-bad {
  border-color: #ff5e5e!important;
}

.indicator-medium {
  border-color: #ffc636!important;
}

.indicator-good {
  border-color: #1ABC9C!important;
}


ul.innerlist {
  list-style-type: none;
}

ul.innerlist > li {
  font-size: 12px;
}

.page {
  padding-top: 100px!important;
}

/*
 *
 *  INDICATORS
 *
 */

.indicator-danger {
  border-color: #ff5e5e!important;
  background-color: #ff5e5e!important;
  color: white;
}

.indicator-warning {
  border-color: #ffc636!important;
  background-color: #ffc636!important;
  color: white;
}

.indicator-success {
  border-color: #70d26d!important;
  background-color: #70d26d!important;
  color: white;
}

.indicator {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.indicator-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 110px);
  padding-bottom: 10px;
}

.indicator-title {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.indicator-header {
  text-align: center;
  font-size: 18px;
}

/*
 *
 * LOADER
 *
 */

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #007bff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.scrollable-y {
  height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
}

.scrollable-x {
  overflow-x: auto;
}

.react-datepicker-wrapper {
  width: 100%;
}

a.app-title:link {
  text-decoration: none;
}

a.app-title:hover {
  color: white;
}

.app-title {
  color: white;
  font-size: 30px;
}

.footer-link {
  color: #00005dbb;
  text-decoration: underline;
}

.footer {
  height: 60px;
  background-color: white;
  position: fixed;
  bottom:0;
  width: 100%;
  color: #00005dff;
  z-index: 2000;
  padding-top: 20px;
}

.footer-logo {
  vertical-align: baseline;
}

.fa-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  z-index: 1000;
}

.c-pointer {
  cursor: pointer;
}